(function($) {
	$( document ).ready( function() {
		/**
		 * show preloader-icon
		 */
		$( '.sbscrbr-submit-block' ).each( function() {
			var form = $( this ).parent( '.subscrbr-sign-up-form' );
			$( this ).find( 'input.submit' ).click( function() {
				if ( ( $('input[name="cptchpr_number"]').lenght > 0 && $('input[name="cptchpr_number"]').text() != "" ) || $('input[name="cptchpr_number"]').lenght < 0 ) {
                    alert('here');
					var offsetTop  = ( $( this ).outerHeight() - 16 ) / 2,
						offsetLeft = $( this ).outerWidth() + 4;
					$( this ).parent().append( '<div style="position: absolute;top: ' + offsetTop + 'px;left: ' + offsetLeft +'px;width: 16px;height: 16px;background: url( ' + preloaderIconPath + ' );background-size: 100%;"></div>' );
				}
			});
		});
        $('.widget.newsletter form').on('submit',function(){
            $.post(ajaxurl,$(this).serialize(),function(data){
                $('.error_message').remove();
                if (data.status == 'success') {
                    $('#newsletter').html('<p style="display:block !important;">'+data.message+'</div>');
                } else {
                    $('#newsletter').append('<p class="error_message" style="display:block !important;">'+data.message+'</div>');
                }


            },'json');
            return false;
        });

		var getValueFromCookie = function(cookieName) {
			var id = null;
			var name = `${cookieName}=`;
			var ca = document.cookie.split(';');
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) === ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) === 0) {
					id = c.substring(name.length, c.length);
				}
			}
			return id;
		};

		$('.af-element.buttonContainer .submit').click(function() {
			var segmentData = {
				eventName: "Email Subscription Attempt",
				options: {},
				data: {
					email_address: $(this).parent().parent().find("input[id^='awf_field-']").val() || "N/A",
					email_signup_attempt_status: "N/A",
					email_validation_error_type: "N/A",
					palcon_user_id: getValueFromCookie("userid") || "N/A",
					login_status: "not logged",
					user_id: "N/A"
				}
			};

			ga(function (tracker) {
				segmentData.data.ga_client_id = tracker.get('clientId') || "N/A";
			});

			var e = new CustomEvent("dcEvent", { detail: segmentData });
			window.dispatchEvent(e);
		 });
	});
})(jQuery);